<template>
  <div class="page-contain">
    <div class="chart-list">
      <div class="list-item">
        <p class="list-item-title">最近12月合格证使用统计</p>
        <div id="chart1" class="my-chart"></div>
      </div>
      <div class="list-item">
        <p class="list-item-title">最近12月合格证使用重量统计（单位：吨）</p>
        <div id="chart2" class="my-chart"></div>
      </div>
      <div class="list-item">
        <p class="list-item-title">销售流向地域围范统计</p>
        <div id="chart3" class="my-chart"></div>
      </div>
      <div class="list-item">
        <p class="list-item-title">销售流向业务范围统计</p>
        <div id="chart4" class="my-chart"></div>
      </div>
      <div class="list-item">
        <p class="list-item-title">最近12月销售额和采购额统计</p>
        <div id="chart5" class="my-chart"></div>
      </div>
      <div class="list-item">
        <p class="list-item-title">最近几年销售额和采购额环比</p>
        <div id="chart6" class="my-chart"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { request, utils } from '@/utils/baseUtils'
  let echarts = require('echarts')
  export default {
    name: 'home',
    data() {
      return {
        chartObj: {
          myChart1: null,
          myChart2: null,
          myChart3: null,
          myChart4: null,
          myChart5: null,
          myChart6: null,
        },
        chartdata: []
      }
    },
    components: {
    },
    methods: {
    },
    created() {
    },
    mounted(){
      this.initCharts()
    },
    methods: {
      initCharts(){
        for(let i = 1; i <= 6; i++){
          this.chartObj[`myChart${i}`] = echarts.init(document.getElementById([`chart${i}`]))
        }
        this.getdrawMap1('certificatequantitystatistics', 'myChart1')
        this.getdrawMap1('certificateweightstatistics', 'myChart2')

        this.getdrawMap2('salesflowarea', 'myChart3')
        this.getdrawMap2('salesdirectionstatistics', 'myChart4')
        this.getdrawMap3('monthlysalesandpurchasestatistics', 'myChart5')
        this.getdrawMap4('annualsalesandpurchasestatistics', 'myChart6')
        this.resizeCharts()
      },
      resizeCharts(){
        window.addEventListener('resize', () => {
          for(let i = 1; i <= 6; i++){
            this.chartObj[`myChart${i}`].resize()
          }
        })
      },
      getdrawMap1(url, chartName){
        let _this = this
        request[url]({
          bodyId: utils.getBodyId()
        }).then((res) => {
          if(res.code == 200){
            _this.drawMap1(chartName, res.data.xAxis.data || [], res.data.series[0].data || [])
          }else{
            _this.drawMap1(chartName, [], [])
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          _this.drawMap1(chartName, [], [])
        })
      },
      drawMap1(chartName, xAxisData, seriesData) {
        let title = chartName == 'myChart1' ? '合格证张数' : '合格证使用重量'
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            right: 40
          },
          grid: {
            top: 45,
            right: 30,
            left: 40
          },
          xAxis: [
            {
              type: 'category',
              axisLabel:{ 
                interval: 0,
                rotate: 30,
                margin: 22,
                padding: [0, -22, 0, 0]
              },
              axisTick:{
                show: true,
                inside: true,
                // alignWithLabel: true 
              },
              data: xAxisData
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLine: {
                show: true
              },
              splitLine: {
                show: false
              },
              axisTick:{
                show: true,
                inside: true 
              },
            }
          ],
          series: [
            {
              name: title,
              type: 'bar',
              barGap: 0,
              itemStyle: {
                color: '#4B9DE8',
              },
              barMaxWidth: 80,
              barGap: '10%',
              data: seriesData
            }
          ]
        }
        this.chartObj[chartName].setOption(option)
      },
      getdrawMap2(url, chartName){
        let _this = this
        request[url]({
          bodyId: utils.getBodyId()
        }).then((res) => {
          if(res.code == 200){
            _this.drawMap2(chartName, res.data)
          }else{
            _this.drawMap2(chartName, [])
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          _this.drawMap2(chartName, [])
        })
      },
      drawMap2(chartName, data) {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            type: 'scroll',
            right: 10,
            top: 20,
            bottom: 20
          },
          grid: {
            right: 120,
          },
          series: [
            {
              type: 'pie',
              radius: '85%',
              center: ['40%', '50%'],
              data: data || [],
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.chartObj[chartName].setOption(option)
      },
      getdrawMap3(url, chartName){
        let _this = this
        request[url]({
          bodyId: utils.getBodyId()
        }).then((res) => {
          if(res.code == 200){
            _this.drawMap3(chartName, res.data.xAxis.data || [], res.data.series || [[],[]])
          }else{
            _this.drawMap3(chartName, [], [[],[]])
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          _this.drawMap3(chartName, [], [[],[]])
        })
      },
      drawMap3(chartName, xAxisData, seriesData) {
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            right: 40
          },
          grid: {
            right: 50,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
            axisLabel:{ 
              rotate: 30,
              margin: 15
            },
            axisTick:{
              show: true,
              inside: true 
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: true
            },
            axisTick:{
              show: true,
              inside: true 
            },
            splitLine: {
              show: false
            },
          },
          series: [
            {
              name: '销售额统计',
              type: 'line',
              // stack: 'Total',
              symbol: 'squareRatio',
              // symbolSize: 6,
              itemStyle: {
                color: '#4B9DE8',
              },
              data: seriesData[0].data
            },
            {
              name: '采购额统计',
              type: 'line',
              // stack: 'Total',
              symbol: 'squareRatio',
              // symbolSize: 6,
              itemStyle: {
                color: '#C1272D',
              },
              data: seriesData[1].data
            }
          ]
        }
        this.chartObj[chartName].setOption(option)
      },
      getdrawMap4(url, chartName){
        let _this = this
        request[url]({
          bodyId: utils.getBodyId()
        }).then((res) => {
          if(res.code == 200){
            _this.drawMap4(chartName, res.data.xAxis.data || [], res.data.series || [[],[]])
          }else{
            _this.drawMap4(chartName, [], [[],[]])
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          _this.drawMap4(chartName, [], [[],[]])
        })
      },
      drawMap4(chartName, xAxisData, seriesData) {
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            right: 40
          },
          grid: {
            right: 50,
          },
          xAxis: [
            {
              type: 'category',
              // axisTick: { show: false },
              data: xAxisData,
              axisTick:{
                show: true,
                inside: true 
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLine: {
                show: true
              },
              splitLine: {
                show: false
              },
              axisTick:{
                show: true,
                inside: true 
              },
            }
          ],
          series: [
            {
              name: '销售额统计',
              type: 'bar',
              barGap: 0,
              itemStyle: {
                color: '#4B9DE8',
              },
              barMaxWidth: 70,
              barGap: '10%',
              data: seriesData[0].data
            },
            {
              name: '采购额统计',
              type: 'bar',
              itemStyle: {
                color: '#C1272D',
              },
              barMaxWidth: 70,
              barGap: '10%',
              data: seriesData[1].data
            }
          ]
        }
        this.chartObj[chartName].setOption(option)
      },
      genData(count) {
        const nameList = []
        const legendData = [];
        const seriesData = [];
        for (var i = 0; i < count; i++) {
          var name =
            Math.random() > 0.65
              ? makeWord(4, 1) + '·' + makeWord(3, 0)
              : makeWord(2, 1);
          legendData.push(name);
          seriesData.push({
            name: name,
            value: Math.round(Math.random() * 100000)
          });
        }
        return {
          legendData: legendData,
          seriesData: seriesData
        };
        function makeWord(max, min) {
          const nameLen = Math.ceil(Math.random() * max + min);
          const name = [];
          for (var i = 0; i < nameLen; i++) {
            name.push(nameList[Math.round(Math.random() * nameList.length - 1)]);
          }
          return name.join('');
        }
      },
    },
    beforeUpdate() {},
    beforeMount() {
    }
  }
</script>
<style lang="scss" scoped>
  .chart-list{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .my-chart{
    height: 330px;
    width: 100%;
  }
  .list-item{
    width: 49%;
    height: 367px;
    border-radius: 25px;
    background-color: #fff;
    position: relative;
    margin-top: 15px;
  }
  .list-item-title{
    padding: 10px 15px 8px;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    padding-left: 15px;
  }
</style>
